import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { StoreByQrCode, StoreDto } from '../interfaces/store.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorage } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  public merchant$: BehaviorSubject<StoreDto | null> = new BehaviorSubject<StoreDto | null>(null);

  @LocalStorage('selectedMerchant', {})
  private selectedMerchantFromStorage$!: BehaviorSubject<StoreDto>;

  @LocalStorage('activeQrCode', '')
  public activeQrCode$!: BehaviorSubject<string | null>;

  @LocalStorage('sessionToken', '')
  public sessionToken$!: BehaviorSubject<string | null>;

  private httpClient = inject(HttpClient);
  
  constructor() {
      if (this.selectedMerchantFromStorage$.value.StoreId)
      {
        this.merchant$.next(this.selectedMerchantFromStorage$.value);
      }
      else
      {
        this.merchant$.next(null);
      }
   }

  public retrieveStoreByQrCode(qrcode: string): Observable<StoreDto> {
    return this.httpClient.post<StoreByQrCode>(environment.apiUrl + '/store/getstorebyqrcode', { qrcode: qrcode })
        .pipe(map((store: StoreByQrCode) => {

          if (!store.Store) throw ''; // do not show wich error occured for safety

          this.activeQrCode$.next(qrcode);
          this.selectedMerchantFromStorage$.next(store.Store);
          this.merchant$.next(store.Store);
          this.sessionToken$.next(store.SessionToken);

          // not in use for qober
          store.Store.MinimumOrderAmount = 0;

          return store.Store;
        }));
  }
}
